import Vue from 'vue'
import get from 'lodash/get'

// axios
import axios from 'axios'

axios.defaults.withCredentials = true

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  headers: { 'X-Authorized-Role': 'institution' },
})

axiosIns.defaults.withCredentials = true
axiosIns.interceptors.response.use(response => response, async err => {
  const status = get(err, 'response.status')
  if (status === 419) {
    await axios({ baseURL: process.env.VUE_APP_API_URL, url: '/csrf-cookie' })
    return axios(err.response.config)
  }
  return Promise.reject(err)
})

Vue.prototype.$http = axiosIns

export default axiosIns
